.langbro-page{
    .lbpage-wrapper{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0%;
        bottom: 0%;
        right: 0%;
        left: 0%;
        .lbpage-texts{
            padding-right: 85px;
            float: left;
            width: 35%;
            margin: auto; 
            .lbpage-header{
                font-size: 40px;
                color: var(--color-text-pink);
                margin-bottom: 45px;
                font-weight: bolder;
            }
            .lbpage-text1{
                color: var(--color-text-pink);
                margin-bottom: 20px;
            }
            .lbpage-text2{
                color: var(--color-display-pink);
            }
        }
        .lbpage-images{
            transform: translate(-18%, 5%);
            margin-top: 60px;
            width: 42%;
            float: left;
            .lbpage-image{
                animation: laptop 2s ease-in-out infinite alternate;
            }
            .lbpage-icon{
                transform: translate(81%, -82%);
            }
        }
    }
}

@keyframes laptop{
    to{
        transform: translateY(15%);
    }
}