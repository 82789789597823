/* 
  ---INDEX 
    2. Font Styles
*/

/*  ================
      Fonts Styles
    ================*/

body {
  font-family: 'Josefin Sans';
  font-weight: 400;
  margin: 0;
  color: white;
  background-color: var(--color-black-shade);
  
  &.no-scroll {
    overflow-y: hidden;
  }

  *{
    font-weight: 400;
  }

  h1,
  p{
    margin: 0;
  }
}

.root{
  width: 100%;
  height: 100%;
}
