/* 
  ---INDEX
    1. Toggle Layout 
*/

/*  ====================
    1. Toggle Layout
  ====================*/

.menu-toggle{
  padding: 1em;
  position: absolute;
  right: .8em;
  top: 1.35em;
  cursor: pointer;
  z-index: 5;
}

.hamburger,
.hamburger::before,
.hamburger::after{
  background: white;
  height: 2.5px;
  width: 1.75em;
  border-radius: 3px;
  content: '';
  display: block;
  transition: all ease-in-out 350ms;
}

.hamburger{
  &.o{
    transform: rotate(45deg);
    height: 3px;

    &::before{
      opacity: 0;
    }
    &::after{
      transform:  translateY(-2px) rotate(-90deg);
      height: 3px;

    }
  }
  &::before{
    transform: translateY(-7px);
  } 
  &::after{
    transform: translateY(4px);
  }
}
