/* 
  ---INDEX
    1. Page Layout 
    2. Font Styles
    3. Animations
*/

/*  ====================
    1. Page Layout
  ====================*/

  .homepage-contact{
    background-color: var(--color-black);
  }

  .top-half-background{
    height: 47vh; 
    width: 100vw; 
    background-color: var(--color-black-shade);
    position: relative;
    overflow: hidden;

    display: flex;
    align-items: center;

    .sliding-text{
      $bezier: cubic-bezier(.48,0,.5,1);
      width: 100%; 
      -webkit-animation: example1 15s $bezier infinite;
      -moz-animation: example1 15s $bezier infinite;
      animation: example1 15s $bezier infinite;
      overflow: visible
    }
  }
  
  .bottom-half-background{
    display: flex;
    flex-direction: column;
    background-color: var(--color-black);
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 53vh;
  
    .imagotype{
      width: auto;
      max-height: 59px;
    }

    img{
      width: 75%;
    }

  }
  
  /*  ====================
        2. Fonts Styles
      ====================*/
  
  .display-text{
    font-size: 8rem;
    text-transform: uppercase;
    color: var(--color-black);
    white-space: nowrap;
    font-weight: 800;
  }

  .subtitle{
    font-size: 32px;
    letter-spacing: 1px;
    margin: 5vh;
  }

/*=================
    3. Animations    
  =================*/

  @keyframes example1 {
    0%   { 
      transform: translateX(0%); /* Firefox bug fix */
      }
      50% { 
      transform: translateX(-75%); /* Firefox bug fix */
    }
      100% { 
      transform: translateX(0%); /* Firefox bug fix */
    }
  }

  @-moz-keyframes example1 {
    0%   { 
    -moz-transform: translateX(0%); /* Firefox bug fix */
    }
    50% { 
    -moz-transform: translateX(-75%); /* Firefox bug fix */
  }
    100% { 
    -moz-transform: translateX(0%); /* Firefox bug fix */
  }
  } 

  @-webkit-keyframes example1 {
    0%   { 
      -webkit-transform: translateX(0%); /* Firefox bug fix */
      }
      50% { 
      -webkit-transform: translateX(-75%); /* Firefox bug fix */
    }
      100% { 
      -webkit-transform: translateX(0%); /* Firefox bug fix */
    }
  }
