.homepage-projects{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .container-full{
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .container-icon{
        margin-bottom: 7vh;
        .box-item{
            cursor: pointer;
            background-color: var(--color-back-box-pink);
            box-sizing: border-box;
            float: left;
            margin: 10px;
            width: 377px;
            height: 456px;
            visibility: visible;
            position: relative;
            border-radius: 20px;
            transition: background-image ease-in-out;
            .project-icon{
                position: absolute;
                margin: auto;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
            }
        }
        .pic1:hover{
            background-image: url(../../../assets/image/back29k.png);
            background-size: contain;
            .project-icon{
                transform: scale(1.1);
            }
        }
        .pic2:hover{
            background-image: url(../../../assets/image/backlongbro.png);
            background-size: contain;
            .project-icon{
                transform: scale(1.1);
            }
        }
    }
    .subtitle{
        bottom: 0px;
        left: calc(73vw);
        position: absolute;
    }
}





