/* 
  ---INDEX 
    1. Font Imports
    2. Colours
    3. Animations
*/



/*  ====================
      1. Font Imports
    ====================*/

@font-face {
  font-family: 'Josefin Sans';
  src: url('/assets/fonts/Josefin_Sans/JosefinSans-VariableFont_wght.ttf');
  font-style: normal;
}   

@font-face {
  font-family: 'Josefin Sans';
  src: url('/assets/fonts/Josefin_Sans/JosefinSans-Italic-VariableFont_wght.ttf');
  font-style: italic, oblique;
}

@font-face {
  font-family: 'Granite';
  src: url('/assets/fonts/Granite_Font_Family/Granite 400.ttf');
  font-style: normal;
}

/*  ==============
      2. Colours
    ==============*/

:root{
  --color-black: #000000;
  --color-white: #ffffff;
  --color-black-shade: #14161A; 
  --color-back-box-pink: #D5B3B3;
  --color-text-pink: #FFF7ED;
  --color-text-pink-second: #FFF7EDCC; 
  --color-display-pink: #EEBECE;
  --color-blue: #0B24FB; 
  --color-yellow: #F8F025;


/*  =================
      3. Animations
    =================*/

    --bezier: cubic-bezier(.3, -0.01, .49, 1);

}