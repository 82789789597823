.homepageaboutus{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3em 3em 3em 5em;
    height: 100vh;
    .hp-au-texts{
        width: 43%;
        .hp-au-text1{
            font-size: 40px;
            font-weight: bolder;
            color: var(--color-display-pink);
            text-decoration: line-through;
        }
        .hp-au-text2{
            font-size: 40px;
            font-weight: bolder;
            color: var(--color-display-pink);
            margin-bottom: 38px;
        }
        .hp-au-text3{
            color: var(--color-text-pink-second);
            line-height: 30px;
            margin-bottom: 65px;
            span{
                color: var(--color-yellow);
                font-size: 40px;
                font-weight: bolder;
                span{
                    color: var(--color-display-pink);
                }
            }
        }
        .hp-au-subtitle{
        }
    }
    .hp-au-animation{
        width: 50%;
    }
}
