$primary-back-color: #14161A !default;

.carousel-gallery{
  margin: 0;
  background: var(--color-black-shade);
  display: flex; // To center the carousel vertically in the viewport
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  .container{
    width: 100%;
    background: var(--color-black-shade);
    padding-bottom: 145px;
    .carousel{
      .carousel-item{
        .carousel-img{
          height: 288px;
          border-radius: 4px;
          box-shadow: 0px 0px 20px var(--color-black);
          width: 100%;
        }
      }
    }
    .subtitle-text{
      justify-content: center;
      font-size: 60px;
      font-family: "Granite";
      .ideas-main{
        opacity: 0.8;
        color: var(--color-text-pink);
        margin-left: 160px;
        .ideas-text{
          color: var(--color-display-pink);
          opacity: 1;
        } 
      }
    }
  }
}
