.link-button{
  color: var(--color-text-pink);
  opacity: 0.8;
  width: fit-content;
  font-family: 'Granite';
  font-size: 24px;
  text-align: center;
  margin-bottom: 3vh;
  .link{
    margin-bottom: 10px;
  }
  a{
      text-decoration: none;
      color: var(--color-text-pink);
  }
  img{
      display: block;
      margin: 0 auto;
  }
}